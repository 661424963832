import { BrowserClient } from "@sentry/browser";
import * as api from "./api";

const SentryClient = new BrowserClient({
  dsn: "https://1842aec436284204ac8a2eedfab9ca44@sentry.io/1820972",
  enabled: process.env.NODE_ENV === "production",
  beforeSend: async event => {
    try {
      const user = await api.getCurrentUser();
      const u = event.user || {};
      u.id = user ? user.uid : undefined;
    } catch (e) {}
    analytics.track("Error generated", {
      id: event.event_id,
      msg: event.message
    });
    return event;
  }
});

let captureException: (e: any) => void;

if (process.env.NODE_ENV === "production") {
  captureException = SentryClient.captureException;
} else {
  captureException = (e: any) => {};
}

export { captureException };
