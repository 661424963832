import { useState, useEffect } from "react";
import { useLocation } from "react-router";

export default function useTrackPageChange() {
  const [prevPathname, setPrevPathname] = useState("");
  const { pathname } = useLocation();

  useEffect(() => {
    if (prevPathname !== pathname) {
      analytics.page(pathname);
      setPrevPathname(pathname);
    }
  }, [pathname]);
}
