import React, { ReactElement, useState } from "react";
import { Form, Icon, Input, Radio, Button, Select } from "antd";
import Url from "url-parse";
import { DbConnection, DbKind } from "./AppTypes";
import * as api from "./api";

const { Option } = Select;

interface Props {
  title?: string;
  url?: string;
  kind?: DbKind;
  saveInProgress: boolean;
  onSubmit(db: { kind: string; title: string; connection: DbConnection }): void;
}

interface FieldErrors {
  title?: string;
  url?: string;
  global?: string;
}

export default function EditDatabase({
  title: initTitle,
  url: initUrl,
  kind: initKind,
  saveInProgress,
  onSubmit
}: Props): ReactElement {
  const [values, setValues] = useState({
    title: initTitle || "",
    url: initUrl || "",
    kind: initKind || DbKind.postgres
  });
  const [errors, setErrors] = useState<FieldErrors>({});

  const getChangeHandler = (key: "url" | "title" | "kind") => ({
    target: { value }
  }: {
    target: { value?: string };
  }) => {
    setValues(values => ({ ...values, [key]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setErrors({ title: undefined, url: undefined, global: undefined });

    const { kind, title, url } = values;

    const [titleErr, t] = parseTitle(title);
    const [urlErr, connection] = parseUrl(url);
    if (titleErr || urlErr) {
      setErrors({
        title: titleErr,
        url: urlErr
      });
      return;
    }

    if (
      kind !== DbKind.postgres &&
      kind !== DbKind.mysql &&
      kind !== DbKind.mongodb
    ) {
      throw new Error(`Unexpected kind, got ${kind}`);
    }

    const db = {
      kind: kind as DbKind,
      title: t as string,
      connection: connection as DbConnection
    };

    try {
      const res = await api.testDb(db);
      if (res.status === "fail") {
        setErrors({ global: `Problem validating database: ${res.error}` });
      } else {
        onSubmit(db);
      }
    } catch (e) {
      console.error(`Caught exception: ${e}`);
      setErrors({
        global: `Encountered an error when validating the database - this may have been on us :-( Try again?`
      });
    }
  };

  const { title: titleError, url: urlError, global: globalError } = errors;

  return (
    <Form style={{ display: "grid" }} onSubmit={handleSubmit}>
      <Form.Item label="Type">
        <Radio.Group
          onChange={getChangeHandler("kind")}
          value={values.kind}
          size="large"
        >
          <Radio.Button value={DbKind.postgres}>Postgres</Radio.Button>
          <Radio.Button value={DbKind.mysql}>MySQL</Radio.Button>
          <Radio.Button value={DbKind.mongodb}>MongoDB</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label="Title"
        validateStatus={titleError ? "error" : ""}
        help={titleError || ""}
      >
        <Input
          value={values.title}
          onChange={getChangeHandler("title")}
          placeholder={getTitlePlaceholder(values.kind)}
        />
      </Form.Item>
      <Form.Item
        label="URL"
        validateStatus={urlError ? "error" : ""}
        help={urlError || ""}
      >
        <Input
          value={values.url}
          onChange={getChangeHandler("url")}
          placeholder={`${
            values.kind ? values.kind : "postgres"
          }://user:password@ec2-123-456-789-00.compute-1.amazonaws.com:5432/production`}
        />
      </Form.Item>
      {errors.global && <p style={{ color: "#f5222d" }}>{errors.global}</p>}
      <Button
        style={{ width: "120px", justifySelf: "right" }}
        type="primary"
        htmlType="submit"
        loading={saveInProgress}
      >
        {initTitle ? "Save" : "Create"}
      </Button>
    </Form>
  );
}

const getTitlePlaceholder = (kind: DbKind) => {
  switch (kind) {
    case DbKind.mysql:
      return "MySQL - Production";
    case DbKind.mongodb:
      return "MongoDB - Production";
    default:
      return "Postgres - Production";
  }
};

const parseUrl = (
  url: string
): [string, undefined] | [undefined, DbConnection] => {
  if (url.length < 1) {
    return ["cannot be blank", undefined];
  }

  let u: Url;
  try {
    u = new Url(url);
  } catch (e) {
    console.error(e);
    return ["invalid URL entered", undefined];
  }

  const m = u.pathname && u.pathname.match(/\/(.+)/);
  const name = m && m[1];
  if (!name) {
    return ["invalid dbname supplied", undefined];
  }
  const parsed: DbConnection = {
    host: u.hostname,
    port: u.port,
    user: u.username,
    password: u.password,
    dbname: name
  };
  if (!parsed.host || !parsed.port || !parsed.dbname) {
    return [
      "incomplete or invalid URL: Need host, port, and hostname",
      undefined
    ];
  } else {
    return [undefined, parsed];
  }
};

const parseTitle = (title: string) => {
  if (title.length < 1) {
    return ["cannot be blank", undefined];
  }
  return [undefined, title];
};
