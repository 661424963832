import React, { ReactElement, useState } from "react";
import { Form, Input, Button, Select } from "antd";
import styled from "styled-components";

type FieldMap = { [k: string]: string };

interface Props {
  fieldValues: FieldMap;
  fieldNames: string[];
  onSaveAndTest(f: FieldMap): void;
  onSave(f: FieldMap): void;
}

type FieldErrors = { [k: string]: string };

export default function EditTestFields({
  fieldValues: initFieldValues,
  fieldNames,
  onSaveAndTest,
  onSave
}: Props): ReactElement {
  const [fields, setFields] = useState(initFieldValues);
  const [errors, setErrors] = useState<FieldErrors>({});

  const getChangeHandler = (key: string) => ({
    currentTarget: { value }
  }: React.ChangeEvent<HTMLInputElement>) => {
    setFields(values => ({ ...values, [key]: value }));
  };

  const handleSaveAndTest = (e: React.FormEvent) => {
    e.preventDefault();
    handleSubmit(onSaveAndTest);
  };

  const handleSave = (e: React.FormEvent) => {
    e.preventDefault();
    handleSubmit(onSave);
  };

  const handleSubmit = async (onSubmit: (f: FieldMap) => void) => {
    setErrors({});
    const emptyFields = fieldNames.filter(name => {
      const val = fields[name];
      return typeof val !== "string" || val.length < 1;
    });
    if (emptyFields.length > 0) {
      emptyFields.forEach(name =>
        setErrors(errors => ({ ...errors, [name]: "cannot be blank" }))
      );
      return;
    }

    onSubmit(fields);
  };

  return (
    <Form onSubmit={handleSaveAndTest}>
      {fieldNames.map(fieldName => (
        <Form.Item
          key={fieldName}
          label={fieldName}
          validateStatus={errors[fieldName] ? "error" : ""}
          help={errors[fieldName] ?? ""}
        >
          <Input
            value={fields[fieldName] ?? ""}
            onChange={getChangeHandler(fieldName)}
          />
        </Form.Item>
      ))}
      <StyledFormActions>
        <Button
          style={{ width: "120px", gridArea: "save" }}
          onClick={handleSave}
        >
          Save
        </Button>
        <Button
          style={{ width: "120px", gridArea: "saveAndTest" }}
          type="primary"
          htmlType="submit"
        >
          Save & Test
        </Button>
      </StyledFormActions>
    </Form>
  );
}

const StyledFormActions = styled.div`
  display: grid;
  grid-template-columns: 1fr auto auto;
  grid-template-areas: "spacer save saveAndTest";
  grid-column-gap: 10px;
`;
