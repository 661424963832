import React, { Component } from "react";
import * as Sentry from "@sentry/browser";
import { Button, Alert } from "antd";
import { ContentContainer } from "./Styles";
import styled from "styled-components";
import aniSnake from "./images/ani-snake.gif";

interface Props {}
interface State {
  hasError: boolean;
  eventId?: string;
}

export default class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    if (this.state.hasError) {
      //render fallback UI
      return (
        <ContentContainer>
          <StyledError>
            <h2>Ah, shoot</h2>
            <StyledErrorBody>
              <img src={aniSnake} height="20px" />
              <p>Something went wrong. It's been reported to our engineers.</p>
              <p>Want to share more context? Click the button below:</p>
            </StyledErrorBody>
            <div style={{ textAlign: "center" }}>
              <Button
                type="primary"
                onClick={() =>
                  Sentry.showReportDialog({ eventId: this.state.eventId })
                }
              >
                Report feedback
              </Button>
            </div>
          </StyledError>
        </ContentContainer>
      );
    }

    //when there's not an error, render children untouched
    return this.props.children;
  }
}

const StyledError = styled.div`
  max-width: 500px;
  margin: auto;
  padding: 15px;
  background-color: #fff1f0;
  border: 1px solid #ffa39e;
`;

const StyledErrorBody = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  grid-column-gap: 10px;
  margin-bottom: 15px;
  img {
    grid-row: 1 / span 2;
  }
  p {
    margin: 0px;
  }
`;
