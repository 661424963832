import { useLocation } from "react-router-dom";
import qs from "qs";
import { DbConnection, DbKind } from "./AppTypes";
import Mustache from "mustache";

export const useQueryParams = (): { [key: string]: string } => {
  const location = useLocation();

  return qs.parse(location.search.replace(/^\?/, ""));
};

export const useRedirect = () => {
  const params = useQueryParams();

  return params.redirect;
};

export const dbConnectionToUrl = (kind: DbKind, connection: DbConnection) => {
  const { host, port, user, password, dbname } = connection;
  const scheme = dbKindToScheme(kind);
  return `${scheme}${user}:${password}/${host}:${port}/${dbname}`;
};

const dbKindToScheme = (kind: DbKind) => {
  switch (kind) {
    case DbKind.postgres:
      return "postgres://";
    case DbKind.mysql:
      return "mysql://";
    case DbKind.mongodb:
      return "mongodb://";
  }
};

type MustacheToken = ["name" | "text", string, number, number];
export const getRequiredFields = (str: string): string[] => {
  return Mustache.parse(str)
    .filter((t: MustacheToken) => t[0] === "name")
    .map((t: MustacheToken) => t[1]);
};
