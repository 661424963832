import React, { ReactElement, useEffect, useState } from "react";
import styled from "styled-components";

import cactusSrc from "./images/cactus.png";
import { logoutUser } from "./api";
import { Icon } from "antd";
import { Link } from "react-router-dom";
import { ContentContainer, MainDiv } from "./Styles";

interface Props {}

export default function Logout({}: Props): ReactElement {
  const [loggedOut, setLoggedOut] = useState(false);
  useEffect(() => {
    const doLogout = async () => {
      await logoutUser();
      setLoggedOut(true);
    };
    doLogout();
  }, []);
  return (
    <ContentContainer>
      <MainDiv>
        <StyledGrid>
          <img height="26px" src={cactusSrc} />
          <p style={{ margin: "0px" }}>
            {loggedOut ? (
              <>Logged out. Until next time, partner.</>
            ) : (
              <>
                <Icon type="loading" />
                Logging you out...
              </>
            )}
          </p>
        </StyledGrid>
        <p style={{ textAlign: "center" }}>
          {loggedOut && <Link to="/">Go home</Link>}
        </p>
      </MainDiv>
    </ContentContainer>
  );
}

const StyledGrid = styled.div`
  margin: auto;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 30px;
  align-items: center;
  grid-column-gap: 10px;
  p {
    font-size: 1.2em;
  }
`;
