import React, { ReactElement, useContext } from "react";

import { message, Icon, Alert, PageHeader, Button, Popconfirm } from "antd";
import {
  Redirect,
  Route,
  RouteProps,
  Link,
  useHistory,
  useLocation
} from "react-router-dom";
import { History } from "history";
import styled from "styled-components";
import { LoginStatusContext, Status } from "./LoginStatus";
import { getCurrentUser } from "./api";
import moment from "moment";
import { Brand } from "./Styles";

export const UserIdContext = React.createContext<string>(null as any);

export default function PrivateRoute(props: RouteProps): ReactElement {
  const loginStatus = useContext(LoginStatusContext);
  const { pathname } = useLocation();
  const history = useHistory();
  const { children, ...rest } = props;
  return (
    <Route
      {...rest}
      render={() => {
        if (loginStatus === Status.LoggingIn) {
          return (
            <StyledContainer>
              <Icon
                type="loading"
                style={{ fontSize: "24px", gridColumn: 2 }}
              />{" "}
              Loading...
            </StyledContainer>
          );
        }
        if (loginStatus === Status.LoggedOut) {
          message.info("You need to login first.");
          return <Redirect to="/" />;
        } else {
          const user = getCurrentUser();
          if (!user) {
            throw new Error(`PrivateRoute Expected a user to be set.`);
          }
          const renderHeader = () => {
            if (pathname.match(/^\/walkthrough/)) return null;

            if (user.isAnonymous) {
              return (
                <>
                  <AnonymousUserWarning
                    createdAt={user.metadata.creationTime}
                  />
                  <LogoutBar isAnonymous={true} history={history} />
                </>
              );
            } else {
              return (
                <LogoutBar isAnonymous={user.isAnonymous} history={history} />
              );
            }
          };

          return (
            <>
              {renderHeader()}
              <UserIdContext.Provider value={user.uid}>
                {props.children}
              </UserIdContext.Provider>
            </>
          );
        }
      }}
    />
  );
}

const LogoutBar = ({
  isAnonymous,
  history
}: {
  isAnonymous: boolean;
  history: History;
}) => {
  const buttons = isAnonymous ? (
    <>
      <Button onClick={() => history.push("/signup")} type="primary" key="1">
        Sign Up
      </Button>
      <Popconfirm
        title="Your account is temporary. Are you sure you want to logout? All your API endpoints will be deleted. To save, sign-up first."
        okText="Delete Visitor Account"
        placement="bottomLeft"
        okType="danger"
        onConfirm={() => history.push("/logout")}
      >
        <Button key="2">Logout</Button>
      </Popconfirm>
    </>
  ) : (
    <Button type="ghost" onClick={() => history.push("/logout")}>
      Logout
    </Button>
  );
  return (
    <PageHeader
      backIcon={false}
      title={<Brand onClick={() => history.push("/")} />}
      extra={buttons}
    />
  );
};

const AnonymousUserWarning = ({ createdAt }: { createdAt?: string }) => {
  let text = "It will be deleted 24 hours after it was created.";
  const hours =
    createdAt &&
    moment(createdAt).diff(moment().subtract("hours", 24), "hours");
  if (hours && hours < 2) {
    text = `It will be deleted very soon.`;
  } else if (hours) {
    text = `It will be deleted in about ${hours} hours.`;
  }

  return (
    <Alert
      message={
        <>
          Heads up! This is a temporary user account. {text}{" "}
          <Link to="/signup">Click here to signup and save your data.</Link>
        </>
      }
      banner
    />
  );
};

const StyledContainer = styled.div`
  display: grid;
  margin: auto;
  width: 100%;
  grid-template-columns: 1fr auto auto 1fr;
  grid-column-gap: 15px;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
`;
