import React, { ReactElement, useState, useContext, useEffect } from "react";
import { Form, Input, Button } from "antd";
import {
  VerticallyCenteredContentContainer,
  MainDiv,
  ContentContainer
} from "./Styles";
import * as api from "./api";
import { useHistory } from "react-router";
import { useRedirect } from "./util";
import { LoginStatusContext, Status } from "./LoginStatus";

interface EmailPasswordProps {
  onSuccess(): void;
  type: "signup" | "signin";
}

export const SignIn = () => {
  const redirect = useRedirect();
  const history = useHistory();
  const loginStatus = useContext(LoginStatusContext);

  useEffect(() => {
    if (loginStatus === Status.LoggedIn) {
      history.push("/queries");
    }
  }, [loginStatus]);

  return (
    <VerticallyCenteredContentContainer>
      <ContentContainer style={{ gridTemplateColumns: "minmax(300px,500px)" }}>
        <MainDiv>
          <EmailPasswordForm
            onSuccess={() =>
              redirect ? history.push(redirect) : history.push("/queries")
            }
            type="signin"
          />
        </MainDiv>
      </ContentContainer>
    </VerticallyCenteredContentContainer>
  );
};

export const SignUp = () => {
  const history = useHistory();
  return (
    <VerticallyCenteredContentContainer>
      <ContentContainer style={{ gridTemplateColumns: "minmax(300px,500px)" }}>
        <MainDiv>
          <EmailPasswordForm
            onSuccess={() => history.push("/queries")}
            type="signup"
          />
        </MainDiv>
      </ContentContainer>
    </VerticallyCenteredContentContainer>
  );
};

const EmailPasswordForm = ({
  onSuccess,
  type
}: EmailPasswordProps): ReactElement => {
  const [values, setValues] = useState({
    email: "",
    password: ""
  });
  const [errors, setErrors] = useState<{
    email?: string;
    password?: string;
    global?: string;
  }>({});
  const [loggingIn, setLoggingIn] = useState(false);

  const getChangeHandler = (field: "email" | "password") => ({
    target: { value }
  }: {
    target: { value: string };
  }) => {
    setValues(values => ({ ...values, [field]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setErrors({});
    const { email, password } = values;
    const emailErr = validateEmail(email);
    const passwordErr = validatePassword(password);

    if (emailErr || passwordErr) {
      setErrors({ email: emailErr, password: passwordErr });
      return;
    }

    try {
      setLoggingIn(true);
      const analyticsId = analytics.user() && analytics.user().id();
      if (type === "signin") {
        const id = await api.signInUser(email, password);
        if (analyticsId) {
          analytics.alias(id);
        }
        analytics.identify(id);
        analytics.track("User signed-in");
      } else {
        // signup
        const id = await api.signUpUser(email, password);
        if (analyticsId) {
          analytics.alias(id);
        }
        analytics.identify(id, { email });
        analytics.track("User signed-up");
      }
      setLoggingIn(false);
      onSuccess();
    } catch (error) {
      setLoggingIn(false);
      const { code, message } = error;
      let msg: string;
      if (code === FirebaseErrorCodes.emailTaken) {
        msg = "That email is already taken.";
      } else if (code === FirebaseErrorCodes.invalidEmail) {
        msg = "That email is invalid.";
      } else if (code === FirebaseErrorCodes.weakPassword) {
        msg = "That password is too weak.";
      } else if (code === FirebaseErrorCodes.invalidPassword) {
        msg = "Password is invalid or user does not exist.";
      } else if (code === FirebaseErrorCodes.loginRequestLimit) {
        msg = "Too many unsuccessful login attempts. Try again later.";
      }
      setErrors(errors => ({
        ...errors,
        global: msg ? msg : `[${code}]: ${message}`
      }));
    }
  };

  const title = type === "signin" ? "Sign in" : "Sign up";

  return (
    <>
      <h1>{title}</h1>
      <Form onSubmit={handleSubmit} style={{ display: "grid" }}>
        <Form.Item
          label="Email"
          validateStatus={errors.email ? "error" : ""}
          help={errors.email || ""}
        >
          <Input
            value={values.email}
            onChange={getChangeHandler("email")}
            placeholder={"user@example.com"}
          />
        </Form.Item>
        <Form.Item
          label="Password"
          validateStatus={errors.password ? "error" : ""}
          help={errors.password || ""}
        >
          <Input
            value={values.password}
            onChange={getChangeHandler("password")}
            type="password"
            placeholder={"∙∙∙∙∙∙∙∙∙∙∙∙∙∙"}
          />
        </Form.Item>
        {errors.global && <p style={{ color: "#f5222d" }}>{errors.global}</p>}
        <Button
          style={{ width: "120px", justifySelf: "right" }}
          type="primary"
          htmlType="submit"
          loading={loggingIn}
        >
          {title}
        </Button>
      </Form>
    </>
  );
};

// https://firebase.google.com/docs/reference/js/firebase.auth.Auth.html#createuserwithemailandpassword
enum FirebaseErrorCodes {
  emailTaken = "auth/email-already-in-use",
  invalidEmail = "auth/invalid-email",
  opNotAllowed = "auth/operation-not-allowed",
  weakPassword = "auth/weak-password",
  invalidPassword = "auth/wrong-password",
  loginRequestLimit = "auth/too-many-requests"
}

const validateEmail = (email: string) => {
  if (email.length < 1) {
    return "Please enter your email";
  }
  if (!email.match(EMAIL_REGEX)) {
    return "Please enter a valid email";
  }
};

const validatePassword = (password: string) => {
  if (password.length < 8) {
    return "Please enter a password 8 characters or longer.";
  }
};

// http://emailregex.com/
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
