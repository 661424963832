import React, { ReactElement, useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { Button, message, PageHeader } from "antd";
import { useHistory } from "react-router-dom";
import { loginAnonymousUser } from "./api";
import { LoginStatusContext, Status } from "./LoginStatus";
import {
  ContentContainer,
  VerticallyCenteredContentContainer,
  MainDiv,
  Brand
} from "./Styles";

interface Props {}

export default function GetStarted({}: Props): ReactElement {
  const history = useHistory();
  const [loggingInAnon, setLoggingInAnon] = useState(false);
  const loginStatus = useContext(LoginStatusContext);

  useEffect(() => {
    if (loginStatus === Status.LoggedIn) {
      history.push("/queries");
    }
  }, [loginStatus]);

  const handleAnonCreate = async () => {
    setLoggingInAnon(true);
    const anonUserId = await loginAnonymousUser();
    // We may have sent them here with a special Segment identifier
    const analyticsId = analytics.user() && analytics.user().id();
    if (analyticsId) {
      analytics.alias(anonUserId);
    } else {
      analytics.identify(anonUserId);
    }
    analytics.track("Anonymous user signed-in");
    setLoggingInAnon(false);
    history.push("/walkthrough");
  };

  return (
    <VerticallyCenteredContentContainer>
      <ContentContainer style={{ gridTemplateColumns: "minmax(300px,500px)" }}>
        <Brand style={{ fontSize: "3em" }} />
        <MainDiv style={{ gridRowGap: "10px" }}>
          <h1 style={{ textAlign: "center" }}>👋 Welcome</h1>
          <Button
            loading={loggingInAnon}
            onClick={handleAnonCreate}
            style={{ height: "80px" }}
            type="primary"
          >
            <span style={{ fontSize: "1.2em" }}>Get Started</span>
            <br />
            <span style={{ fontSize: "0.9em" }}>
              Your first query in 30 seconds
            </span>
          </Button>
          <Button
            disabled={loggingInAnon}
            onClick={() => history.push("/signin")}
            style={{ height: "40px" }}
          >
            Login
          </Button>
        </MainDiv>
      </ContentContainer>
    </VerticallyCenteredContentContainer>
  );
}

const StyledBrand = styled.h1`
  font-family: "Lora", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
    "Helvetica Neue", sans-serif;
  font-size: 3em;
`;
