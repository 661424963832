import React, { useReducer, useEffect, useContext } from "react";
import styled from "styled-components";
import { Button, Icon, notification, message, Skeleton, Result } from "antd";

import WalkthroughExplainer from "./WalkthroughExplainer";
import EditQuery, { CalloutableComponents } from "./EditQuery";
import * as api from "./api";
import Query from "./Query";
import CalloutBubble from "./CalloutBubble";
import { useHistory } from "react-router";
import { UserIdContext } from "./PrivateRoute";
import TestDatabase from "./TestDatabase";
import { MainDiv, ContentContainer } from "./Styles";
import { runTest } from "./EditQueryContainer";

export enum Steps {
  Init,
  SelectDb,
  EditQuery,
  TestQuery,
  SaveQuery,
  QuerySaved,
  Done
}

interface State {
  step: Steps;
  queryValue: string;
  databaseId?: string;
  url?: string;
  testResult?: api.TestResult;
  testInProgress: boolean;
  saveInProgress: boolean;
  queryId?: string;
  error?: string;
}

const initialState: State = {
  step: Steps.Init,
  queryValue: "",
  testInProgress: false,
  saveInProgress: false
};

// const lastScreenState = {
//   ...initialState,
//   step: Steps.QuerySaved,
//   databaseId: TestDatabase.id,
//   url: "https://api.trykinetik.com/R3rZU7bc70W5hahzuQ606g"
// };

// const editQueryState = {
//   ...initialState,
//   step: Steps.EditQuery,
//   databaseId: TestDatabase.id
// };
const databases = [TestDatabase];

export default function WalkthroughApp() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    step,
    queryValue,
    url,
    databaseId,
    testInProgress,
    saveInProgress,
    queryId,
    error,
    testResult
  } = state;
  const history = useHistory();
  const userId = useContext(UserIdContext);
  const { loading, queries } = api.useFirebase(userId);

  useEffect(() => {
    const query = queries.find(q => q.id === queryId);

    const host = process.env.REACT_APP_API_HOST || "https://api.trydecode.com";

    if (query && query.pathKey && !url) {
      const url = host + `/e/${query.pathKey}`;
      dispatch({ type: Actions.UrlReceived, value: url });
      return;
    }
    if (query && query.requestCount && query.requestCount > 0) {
      dispatch({ type: Actions.UrlQueried });
    }
  }, [queries]);

  // Hack: Returns boolean indicating pass/fail
  const handleTestInitiate = async (): Promise<api.TestResult | undefined> => {
    dispatch({ type: Actions.TestInitiated });

    if (!databaseId) {
      dispatch({
        type: Actions.TestFailedToComplete,
        error: "Please select a database."
      });
      return;
    }
    const [res, err] = await runTest({
      databaseId,
      value: queryValue,
      fields: {}
    });
    if (err) {
      dispatch({
        type: Actions.TestFailedToComplete,
        error: err
      });
      return;
    }
    dispatch({
      type: Actions.TestCompleted,
      result: res as api.TestResult
    });
    return res as api.TestResult;
  };

  // TODO: Refactor
  const handleSave = async () => {
    dispatch({ type: Actions.SaveInitiated });

    if (!databaseId) {
      dispatch({
        type: Actions.SaveFailed,
        error: "Please select a database."
      });
      return;
    }

    const saveGivenTestResult = async (result: api.TestResult) => {
      if (result.status === api.TestResultStatus.ok) {
        const id = await api.createQuery(userId, {
          databaseId,
          value: queryValue
        });
        analytics.track("Walkthrough query created", { databaseId, id });
        dispatch({ type: Actions.SaveCompleted, id });
      } else {
        dispatch({
          type: Actions.SaveFailed,
          error: "Query appears to have an error."
        });
      }
    };

    if (testResult && testResult.query === queryValue) {
      saveGivenTestResult(testResult);
    } else {
      const res = await handleTestInitiate();
      if (!res) {
        dispatch({
          type: Actions.SaveFailed,
          error: "Problem running your test."
        });
        return;
      }
      saveGivenTestResult(res);
    }
  };

  const database = databases[0];

  const renderBody = () => {
    if (step === Steps.Init) {
      return (
        <CalloutBubble active={true}>
          <Button
            style={{ justifySelf: "center" }}
            type="primary"
            onClick={() => dispatch({ type: Actions.AddQueryClicked })}
          >
            <Icon type="plus" />
            Add query
          </Button>
        </CalloutBubble>
      );
    } else if (
      step === Steps.SelectDb ||
      step === Steps.EditQuery ||
      step === Steps.TestQuery ||
      step === Steps.SaveQuery
    ) {
      const getCalloutComponent = (): CalloutableComponents => {
        switch (step) {
          case Steps.SelectDb:
            return "db-select";
          case Steps.EditQuery:
            return "edit-query";
          case Steps.TestQuery:
            return "test-query";
          case Steps.SaveQuery:
            return "save-query";
        }
      };
      const databaseOptions: Array<[string, string]> = databases.map(d => [
        d.id,
        d.title
      ]);
      return (
        <EditQuery
          query={queryValue}
          allDatabases={databaseOptions}
          calloutComponent={getCalloutComponent()}
          databaseId={databaseId}
          testInProgress={testInProgress}
          saveInProgress={saveInProgress}
          testResult={testResult}
          onQueryChange={(v: string) =>
            dispatch({ type: Actions.QueryValueChange, value: v })
          }
          onDbSelect={(id: string) =>
            dispatch({ type: Actions.DbSelected, id })
          }
          onTestInitiate={handleTestInitiate}
          onCancel={() => message.info("There is no going back for now :)")}
          onSave={handleSave}
          error={error}
        />
      );
    } else if (step === Steps.QuerySaved || step === Steps.Done) {
      const isDone = step === Steps.Done;
      return (
        <div style={{ display: "grid", gridRowGap: "10px" }}>
          <Query
            onEdit={() =>
              message.info(
                "You can edit later :) Finish the walkthrough for now"
              )
            }
            onDelete={() =>
              message.info(
                "You can delete later :) Finish the walkthrough for now"
              )
            }
            databaseTitle={database.title}
            url={url as string}
            urlIsGenerating={!url}
            query={queryValue}
          />

          <CalloutBubble active={isDone}>
            <Button
              type="primary"
              style={{ justifySelf: "right" }}
              disabled={!isDone}
              onClick={() => history.push("/queries")}
            >
              {isDone ? "Let's Go" : "Waiting"}
            </Button>
          </CalloutBubble>
        </div>
      );
    }
  };

  if (loading) {
    return (
      <ContentContainer style={{ gridTemplateColumns: "minmax(300px,500px)" }}>
        <MainDiv>
          <Skeleton active></Skeleton>
        </MainDiv>
      </ContentContainer>
    );
  }

  return (
    <ContentContainer>
      <WalkthroughExplainer url={url} step={step} />
      <MainDiv>{renderBody()}</MainDiv>
    </ContentContainer>
  );
}

enum Actions {
  AddQueryClicked,
  DbSelected,
  QueryValueChange,
  TestInitiated,
  TestCompleted,
  TestFailedToComplete,
  SaveInitiated,
  SaveFailed,
  SaveCompleted,
  UrlReceived,
  UrlQueried
}

type ActionObject =
  | {
      type:
        | Actions.AddQueryClicked
        | Actions.UrlQueried
        | Actions.TestInitiated
        | Actions.SaveInitiated;
    }
  | { type: Actions.QueryValueChange; value: string }
  | { type: Actions.DbSelected; id: string }
  | { type: Actions.UrlReceived; value: string }
  | { type: Actions.TestCompleted; result: api.TestResult }
  | { type: Actions.TestFailedToComplete; error: string }
  | { type: Actions.SaveFailed; error: string }
  | { type: Actions.SaveCompleted; id: string };

const reducer = (state: State, action: ActionObject): State => {
  switch (action.type) {
    case Actions.AddQueryClicked: {
      analytics.track("Walkthrough started");
      return {
        ...state,
        step: Steps.SelectDb
      };
    }
    case Actions.DbSelected: {
      const { id } = action;
      return {
        ...state,
        step: Steps.EditQuery,
        databaseId: id
      };
    }
    case Actions.QueryValueChange: {
      const { value } = action;
      return {
        ...state,
        queryValue: value,
        step: Steps.TestQuery
      };
    }
    case Actions.TestInitiated: {
      analytics.track("Walkthrough test started");
      return {
        ...state,
        testInProgress: true
      };
    }
    case Actions.TestCompleted: {
      const { result } = action;
      analytics.track("Walkthrough test completed", {
        status: result.status,
        query: result.query
      });
      return {
        ...state,
        testResult: result,
        testInProgress: false,
        step:
          result.status === api.TestResultStatus.ok
            ? Steps.SaveQuery
            : Steps.TestQuery
      };
    }
    case Actions.TestFailedToComplete: {
      const { error } = action;
      return {
        ...state,
        testInProgress: false,
        error
      };
    }
    case Actions.SaveInitiated: {
      return {
        ...state,
        saveInProgress: true
      };
    }
    case Actions.SaveFailed: {
      const { error } = action;
      return {
        ...state,
        saveInProgress: false,
        error
      };
    }
    case Actions.SaveCompleted: {
      const { id } = action;
      const { queryValue } = state;
      analytics.track("Walkthrough query saved", { query: queryValue });
      return {
        ...state,
        step: Steps.QuerySaved,
        saveInProgress: false,
        queryId: id
      };
    }
    case Actions.UrlReceived: {
      const { value } = action;
      return {
        ...state,
        url: value
      };
    }
    case Actions.UrlQueried: {
      const { url, queryValue } = state;
      analytics.track("Walkthrough executor queried", {
        query: queryValue,
        url
      });
      return {
        ...state,
        step: Steps.Done
      };
    }
  }
};
