import React from "react";
import styled from "styled-components";
import { Icon, Popconfirm, Button } from "antd";
import copy from "clipboard-copy";
import LocalMessage from "./LocalMessage";

interface Props {
  databaseTitle: string;
  url: string;
  query: string;
  isHighlighted?: boolean;
  onEdit(): void;
  onDelete(): void;
  urlIsGenerating?: boolean;
}

const Query: React.FC<Props> = ({
  databaseTitle,
  url,
  query,
  isHighlighted,
  onDelete,
  onEdit,
  urlIsGenerating
}) => {
  const TopBar = getTopBar(isHighlighted);
  return (
    <>
      <TopBar>
        <span style={{ gridColumn: "2" }}>
          <Button onClick={onEdit} type="ghost">
            Edit
          </Button>
        </span>
        <span>
          <Popconfirm
            title="Are you sure?"
            okText="Delete"
            placement="bottom"
            okType="danger"
            onConfirm={onDelete}
          >
            <Icon type="delete" />
          </Popconfirm>
        </span>
      </TopBar>
      <StyledBody>
        <StyledRowHeader>Database</StyledRowHeader>
        <div>{databaseTitle}</div>
        <StyledRowHeader>URL</StyledRowHeader>
        {urlIsGenerating ? (
          <div>
            <Icon type="loading" /> Generating...
          </div>
        ) : (
          <StyledUrl>
            <span>{url}</span>{" "}
            <LocalMessage disappearAfter={1500}>
              {msg => (
                <Icon
                  type="copy"
                  onClick={() => {
                    copy(url);
                    msg("Copied");
                  }}
                />
              )}
            </LocalMessage>
          </StyledUrl>
        )}
        <StyledRowHeader>Query</StyledRowHeader>
        <StyledQuery>{query}</StyledQuery>
      </StyledBody>
    </>
  );
};

// const StyledTitle = styled.span`
//   font-size: 1.1em;
// `;

const StyledUrl = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 10px;
  align-items: center;

  span {
    overflow-x: scroll;
  }
`;

const StyledQuery = styled.pre`
  width: 100%;
  grid-column: 2;
  grid-row: 4;
  margin-top: 10px;
`;

const getTopBar = (highlighted?: boolean) => {
  return highlighted ? HighlightedTopBar : StyledTopBar;
};

const StyledTopBar = styled.div`
  display: grid;
  align-items: center;
  justify-items: flex-start;
  grid-template-columns: 1fr 75px 20px;
  margin: -15px -15px 15px -15px;
  border-radius: 2px;
  background-color: #e6f7ff;
  padding: 5px 15px;
`;

const HighlightedTopBar = styled(StyledTopBar)`
  background-color: #fff1b8;
  -webkit-animation: fade-color 3s ease-in forwards;
  animation: fade-color 3s ease-in forwards;

  @keyframes fade-color {
    from {
      background-color: #fff1b8;
    }
    to {
      background-color: #e6f7ff;
    }
  }
`;

const StyledBody = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 10px;
`;

const StyledRowHeader = styled.div`
  font-weight: 500;
  justify-self: right;
`;

export default Query;
