export interface DbConnection {
  host: string;
  port: string;
  user: string;
  password: string;
  dbname: string;
}

export enum DbKind {
  "mysql" = "mysql",
  "postgres" = "postgres",
  "mongodb" = "mongodb"
}

// export enum DbColor {
//   "magenta",
//   "red",
//   "volcano",
//   "orange",
//   "gold",
//   "lime",
//   "green",
//   "cyan",
//   "blue",
//   "geekblue",
//   "purple"
// }
