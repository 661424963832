import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import WalkthroughApp from "./WalkthroughApp";
import AllQueries from "./AllQueries";
import GetStarted from "./GetStarted";
import LoginStatus from "./LoginStatus";
import PrivateRoute from "./PrivateRoute";
import Logout from "./Logout";
import NotFound from "./NotFound";
import ErrorBoundary from "./ErrorBoundary";
import { SignIn, SignUp } from "./EmailPasswordForm";
import useTrackPageChange from "./useTrackPageChange";

const App: React.FC = () => {
  useTrackPageChange();
  return (
    <ErrorBoundary>
      <LoginStatus>
        <Switch>
          <Route exact path="/demo-video">
            <RedirectToVideoDemo />
          </Route>
          <PrivateRoute exact path="/walkthrough">
            <WalkthroughApp />
          </PrivateRoute>
          <PrivateRoute path="/queries">
            <AllQueries />
          </PrivateRoute>
          <Route exact path="/logout">
            <Logout />
          </Route>
          <Route exact path="/">
            <GetStarted />
          </Route>
          <Route path="/signin">
            <SignIn />
          </Route>
          <Route path="/signup">
            <SignUp />
          </Route>
          <Route>
            <NotFound />
          </Route>
        </Switch>
      </LoginStatus>
      <Route component={ScrollToTop} />
    </ErrorBoundary>
  );
};

const RedirectToVideoDemo = () => {
  useEffect(() => {
    window.location.href =
      "https://www.loom.com/share/f1da522b3fe04eec99fcdf7cde6dc963";
  }, []);
  return null;
};

const ScrollToTop = () => {
  window.scrollTo(0, 0);
  return null;
};

export default App;
