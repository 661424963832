import * as api from "./api";
import { DbKind } from "./AppTypes";

const TestDatabase: api.Database = {
  id: "__testDb1",
  kind: DbKind.postgres,
  title: "Decode Sample Database (Postgres)",
  connection: {
    host: "trykinetik.com",
    port: "5432",
    user: "test",
    password: "test",
    dbname: "postgres-test"
  }
};

export default TestDatabase;
