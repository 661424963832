import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const f = firebase.initializeApp({
  apiKey: "AIzaSyCcDjS9tQQkouDHhLmb4LTWTFiDHr716iA",
  authDomain: "kinetik-8b717.firebaseapp.com",
  databaseURL: "https://kinetik-8b717.firebaseio.com",
  projectId: "kinetik-8b717",
  storageBucket: "kinetik-8b717.appspot.com",
  messagingSenderId: "713797844963",
  appId: "1:713797844963:web:3b7f6af897dbf5f9e6aad6"
});

firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

export default f;
