import React, {
  ReactElement,
  useContext,
  useState,
  useEffect,
  useRef,
  RefObject
} from "react";
import { Button, Icon, Skeleton, message, Spin } from "antd";
import { Switch, Route, useHistory, useLocation } from "react-router-dom";

import EditQueryContainer from "./EditQueryContainer";
import Query from "./Query";
import NotFound from "./NotFound";
import * as api from "./api";
import { UserIdContext } from "./PrivateRoute";
import TestDatabase from "./TestDatabase";
import { captureException } from "./SentryClient";
import { ContentContainer, MainDiv } from "./Styles";

interface Props {}

export default function AllQueries({}: Props): ReactElement {
  const history = useHistory();
  const { state: locationState } = useLocation();
  const highlightQuery = locationState && locationState.highlightQuery;
  const highlightQueryRef = useRef(null);
  const userId = useContext(UserIdContext);
  const { loading, queries, databases } = api.useFirebase(userId);
  const [queryDeleting, setQueryDeleting] = useState<string | null>(null);

  const highlightQueryId =
    highlightQuery && highlightQuery.pushedAt > Date.now() - 5000
      ? highlightQuery.id
      : null;

  useEffect(() => {
    if (highlightQueryId && highlightQueryRef) {
      scrollToRef(highlightQueryRef);
    }
  }, [highlightQueryId, highlightQueryRef]);

  const databaseById = (id: string) =>
    [...databases, TestDatabase].find(d => d.id === id);

  const handleDelete = async (id: string) => {
    setQueryDeleting(id);
    try {
      await api.deleteQuery(userId, id);
      analytics.track("Query deleted", { userId, id });
      message.success("Query deleted");
    } catch (e) {
      captureException(e);
      setQueryDeleting(null);
      message.error(
        "We had trouble deleting that query :( Please try again or contact us."
      );
      return;
    }
  };

  const WrapQueryIfDeleting: React.FC<{ queryId: string }> = ({
    queryId,
    children
  }) => {
    if (queryId === queryDeleting) {
      return (
        <Spin
          indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}
          tip="Deleting..."
        >
          {children}
        </Spin>
      );
    }
    return <>{children}</>;
  };

  const qs = queries.map(q => {
    const db = databaseById(q.databaseId);
    if (!db) {
      throw new Error(`No db found for user matching ${q.databaseId}`);
    }
    const host = process.env.REACT_APP_API_HOST || "https://api.trydecode.com";
    return {
      ...q,
      databaseTitle: db.title,
      url: host + `/e/${q.pathKey}`
    };
  });
  if (loading) {
    return (
      <ContentContainer style={{ gridTemplateColumns: "minmax(300px,500px)" }}>
        <MainDiv>
          <Skeleton active />
        </MainDiv>
      </ContentContainer>
    );
  }
  return (
    <Switch>
      <Route path="/queries/add">
        <EditQueryContainer />
      </Route>
      <Route
        path="/queries/:id"
        render={({
          match: {
            params: { id }
          }
        }) => {
          const q = queries.find(q => q.id === id);
          if (q) {
            const { id, value, databaseId } = q;
            return (
              <EditQueryContainer initialQuery={{ id, value, databaseId }} />
            );
          } else {
            return <NotFound />;
          }
        }}
      />
      <Route path="/queries">
        <ContentContainer
          style={{
            gridRowGap: "15px",
            gridTemplateColumns: "minmax(300px,500px)"
          }}
        >
          {qs.map(({ id, databaseTitle, url, value }, idx) => {
            const isHighlighted = id === highlightQueryId;
            return (
              <WrapQueryIfDeleting key={idx} queryId={id}>
                <MainDiv
                  className={isHighlighted ? "box-shadow-emphasize" : ""}
                  ref={isHighlighted ? highlightQueryRef : null}
                >
                  <Query
                    isHighlighted={isHighlighted}
                    onEdit={() => history.push(`/queries/${id}`)}
                    onDelete={() => handleDelete(id)}
                    databaseTitle={databaseTitle}
                    url={url}
                    query={value}
                  />
                </MainDiv>
              </WrapQueryIfDeleting>
            );
          })}
          <MainDiv>
            <Button
              onClick={() => history.push("/queries/add")}
              style={{ justifySelf: "center" }}
              type="primary"
            >
              <Icon type="plus" />
              Add query
            </Button>
          </MainDiv>
        </ContentContainer>
      </Route>
    </Switch>
  );
}

const scrollToRef = (ref: RefObject<HTMLDivElement>) => {
  if (window && ref) {
    const { current } = ref;
    current && window.scrollTo(0, current.offsetTop);
  }
};
