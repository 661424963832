import React, { ReactElement } from "react";
import styled from "styled-components";

import skullSrc from "./images/skull.png";
import { ContentContainer, MainDiv } from "./Styles";

interface Props {}

export default function NotFound({}: Props): ReactElement {
  return (
    <ContentContainer>
      <MainDiv>
        <StyledGrid>
          <img height="18px" src={skullSrc} />
          <p style={{ margin: "0px" }}>
            Uh oh. The page you're looking for was not found.
          </p>
        </StyledGrid>
      </MainDiv>
    </ContentContainer>
  );
}

const StyledGrid = styled.div`
  margin: auto;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 30px;
  align-items: center;
  grid-column-gap: 10px;
  p {
    font-size: 1.2em;
  }
`;
