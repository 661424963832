import React, { ReactElement, ReactNode, useState, useEffect } from "react";
import Firebase from "./Firebase";

interface Props {
  children: ReactNode;
}

export enum Status {
  LoggingIn,
  LoggedIn,
  LoggedOut
}

export const LoginStatusContext = React.createContext<Status>(Status.LoggingIn);

const LOCAL_STORAGE_KEY = "above.isLoggedIn";

export default function LoginStatus({ children }: Props): ReactElement {
  const [loginStatus, setLoginStatus] = useState(Status.LoggingIn);

  useEffect(() => {
    if (loginStatus === Status.LoggedIn) {
      localStorage.setItem(LOCAL_STORAGE_KEY, "1");
    } else if (loginStatus === Status.LoggedOut) {
      localStorage.removeItem(LOCAL_STORAGE_KEY);
    }
  }, [loginStatus]);

  useEffect(() => {
    const timeout = setTimeout(() => setLoginStatus(Status.LoggedOut), 5000);
    const wasLoggedIn = localStorage.getItem(LOCAL_STORAGE_KEY);
    // in this case, we have no reason to wait for login to occur
    if (!wasLoggedIn) {
      setLoginStatus(Status.LoggedOut);
    }

    const unsubListener = Firebase.auth().onAuthStateChanged(user => {
      if (user) {
        setLoginStatus(Status.LoggedIn);
        clearTimeout(timeout);
      } else {
        setLoginStatus(Status.LoggedOut);
      }
    });

    return () => {
      unsubListener();
      clearTimeout(timeout);
    };
  }, []);

  return (
    <LoginStatusContext.Provider value={loginStatus}>
      {children}
    </LoginStatusContext.Provider>
  );
}
