import React, { useState } from "react";
import styled from "styled-components";
import { Steps } from "./WalkthroughApp";
import CalloutBubble from "./CalloutBubble";
import { Icon } from "antd";
import copy from "clipboard-copy";
import LocalMessage from "./LocalMessage";
import { Collapse } from "antd";

const { Panel } = Collapse;

interface Props {
  step: Steps;
  url?: string;
}

export default function WalkthroughExplainer({ step, url }: Props) {
  const [showQueryExamples, setShowQueryExamples] = useState(false);
  const renderBody = () => {
    switch (step) {
      case Steps.Init: {
        return (
          <>
            <p>
              Decode makes it easy to securely fetch data from your database.
              You can drop Decode URLs right into your frontend app.
            </p>
            <p>In this 30 second walkthrough:</p>
            <ul>
              <li>
                📝 You'll write a SQL query for our ready-to-go sample database.
              </li>
              <li>🔒 Decode will generate a secure URL to run that query.</li>
              <li>🌈 You'll call that URL and receive the results!</li>
            </ul>
            <p>
              <b>Let's write your first query.</b> Click the button below.
            </p>
          </>
        );
      }
      case Steps.SelectDb: {
        return (
          <>
            <b>Select a database</b>
            <p>
              Select <code>Decode Sample Database (Postgres)</code> for now. You
              can connect your own database in a moment.
            </p>
          </>
        );
      }
      case Steps.EditQuery:
      case Steps.TestQuery:
      case Steps.SaveQuery: {
        return (
          <>
            <p>
              Now, let's enter your SQL query. Write the following query in the
              editor below:
            </p>
            <CopyableCommand cmd={`select * from cowboys;`} />
            {showQueryExamples ? (
              <>
                <p>
                  Our testing database has three tables: <code>cowboys</code>,{" "}
                  <code>ranches</code>, and <code>horses</code>.{" "}
                </p>

                <p>
                  Here are some ideas:
                  <Collapse
                    style={{ backgroundColor: "#e6f7ff" }}
                    bordered={false}
                    defaultActiveKey={["1"]}
                    accordion={true}
                  >
                    <Panel
                      header="Count all the American Quarter horses"
                      key="2"
                    >
                      <CopyableCommand cmd={COUNT_AMERICAN_QTR_SQL} />
                    </Panel>
                    <Panel
                      header="Find out how many American Quarter horses are at each ranch"
                      key="3"
                    >
                      <CopyableCommand cmd={AMERICAN_QTR_BY_RANCH_SQL} />
                    </Panel>
                  </Collapse>
                </p>
                {step === Steps.TestQuery && (
                  <b>When you're done writing your query, click "Test."</b>
                )}
                {step === Steps.SaveQuery && (
                  <b>When the test passes, click "Save."</b>
                )}
              </>
            ) : (
              <p>
                Or, want to write a more complex query?{" "}
                <a onClick={() => setShowQueryExamples(true)}>
                  Click here for ideas 💡
                </a>{" "}
              </p>
            )}
          </>
        );
      }
      case Steps.QuerySaved: {
        if (!url) {
          return (
            <>
              <p>Just a moment. We're generating a URL for you...</p>
            </>
          );
        } else {
          return (
            <>
              <p>
                Great! Now just make a <code>GET</code> request to the URL below
                to see your new endpoint in action.
              </p>
              <p>
                Here's an example using <code>curl</code>:
              </p>
              <CalloutBubble>
                <CopyableCommand cmd={`curl ${url}`} />
              </CalloutBubble>
              <p style={{ marginTop: "10px" }}>
                Or just open this URL{" "}
                <a target="_blank" href={url}>
                  in your browser
                </a>
                .
              </p>
              <p>
                <Icon type="loading" /> Awaiting your request...
              </p>
            </>
          );
        }
      }
      case Steps.Done: {
        return (
          <>
            <p>
              <b>🎉 Woo! Tutorial complete.</b>
            </p>
            <p>Now go build something useful.</p>
          </>
        );
      }
      default: {
        return null;
      }
    }
  };

  return (
    <StyledStepExplainerContainer>{renderBody()}</StyledStepExplainerContainer>
  );
}

const CopyableCommand = ({ cmd }: { cmd: string }) => (
  <StyledCopyableCommand>
    <pre style={{ marginBottom: "7px" }}>
      <code>{cmd}</code>
    </pre>
    <LocalMessage disappearAfter={1500}>
      {msg => (
        <Icon
          type="copy"
          onClick={() => {
            copy(cmd);
            msg("Copied");
          }}
        />
      )}
    </LocalMessage>
  </StyledCopyableCommand>
);

const StyledCopyableCommand = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 7px;
  align-items: center;
`;

const StyledStepExplainerContainer = styled.div`
  max-width: 500px;
  margin-bottom: 15px;
  border-radius: 2px;
  background-color: #e6f7ff;
  padding: 15px;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.16);
  position: relative;
`;

const COUNT_AMERICAN_QTR_SQL = `SELECT count(horses) from horses where breed='american
quarter horse';`;

const AMERICAN_QTR_BY_RANCH_SQL = `SELECT ranches.ranch_name, ranches.acres,
count(horses.id) FROM ranches INNER JOIN horses ON
ranches.id = horses.ranch_id WHERE horses.breed =
'american quarter horse' GROUP BY ranches.ranch_name,
ranches.acres;`;
