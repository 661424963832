import React, {
  useState,
  ReactElement,
  useContext,
  useReducer,
  useEffect,
  useRef
} from "react";
import { Modal, message } from "antd";

import * as api from "./api";
import EditQuery from "./EditQuery";
import EditDatabase from "./EditDatabase";
import { UserIdContext } from "./PrivateRoute";
import TestDatabase from "./TestDatabase";
import { DbConnection, DbKind } from "./AppTypes";
import { ContentContainer, MainDiv } from "./Styles";
import { useHistory } from "react-router";
import { captureException } from "./SentryClient";
import { getRequiredFields } from "./util";
import EditTestFields from "./EditTestFields";

type Database = api.Database;

interface Props {
  initialQuery?: {
    id: string;
    databaseId: string;
    value: string;
    testFields?: { [k: string]: string };
  };
}

enum States {
  Idle = "Idle",
  Testing = "Testing",
  TestingBeforeSave = "TestingBeforeSave",
  Saving = "Saving",
  AddingDb = "AddingDb",
  SavingDb = "SavingDb",
  EditingTestFields = "EditingTestFields"
}

interface State {
  stateName: States;
  queryValue: string;
  id?: string;
  databaseId?: string;
  testFields: { [k: string]: string };
  requiredTestFields: string[];
  error?: string;
  testResult?: api.TestResult;
  recentlyCreatedDb?: Database;
}

const EditQueryContainer = ({ initialQuery }: Props): ReactElement => {
  let history = useHistory();
  let userId = useContext(UserIdContext);
  let { loading: databasesLoading, databases } = api.useFirebase(userId);

  let initialState = getInitialState(initialQuery);
  let [state, dispatch] = useReducer(reducer, initialState);
  // Used to keep our useEffect calls for saving idempotent
  let saveStarted = useRef(false);

  let {
    stateName,
    queryValue,
    databaseId,
    error,
    testResult,
    testFields,
    requiredTestFields,
    recentlyCreatedDb
  } = state;
  let database = databases.find(d => d.id === databaseId);

  if (
    recentlyCreatedDb &&
    !databases.find(({ id }) => id === recentlyCreatedDb?.id)
  ) {
    databases.push(recentlyCreatedDb);
  }

  useEffect(() => {
    if (database?.kind === DbKind.mongodb) {
      if (queryValue === "") {
        dispatch({
          type: Actions.QueryValueChanged,
          value: DEFAULT_MONGO_QUERY
        });
      }
    }
  }, [database]);

  useEffect(() => {
    if (saveStarted.current) return;

    const runEffect = async () => {
      saveStarted.current = true;
      if (!databaseId) {
        throw new Error(`Should not have initiated save without databaseId`);
      }

      let id = initialQuery && initialQuery.id;
      let payload = { databaseId, value: queryValue, testFields };
      try {
        if (id) {
          await api.saveQuery(userId, id, payload);
          analytics.track("Query updated", { databaseId, id });
        } else {
          id = await api.createQuery(userId, payload);
          analytics.track("Query created", { databaseId, id });
        }
      } catch (e) {
        captureException(e);
        console.error(e);
        saveStarted.current = false;
        dispatch({
          type: Actions.SaveFailed,
          error: "Something went wrong. It might be on our end :( Try again?"
        });
      }
      history.push("/queries", {
        highlightQuery: { id, pushedAt: Date.now() }
      });
      message.success(initialQuery ? "Query updated" : "Query Created");
    };

    if (stateName === States.Saving) {
      runEffect();
    }
  }, [stateName]);

  useEffect(() => {
    const runEffect = async () => {
      if (!databaseId) {
        throw new Error(`Should not have initiated test without databaseId`);
      }

      let [res, err] = await runTest({
        databaseId,
        value: queryValue,
        id: initialQuery && initialQuery.id,
        fields: testFields
      });
      if (err) {
        dispatch({
          type: Actions.TestFailedToComplete,
          error: err
        });
        return;
      }

      dispatch({
        type: Actions.TestCompleted,
        result: res as api.TestResult
      });

      if (stateName === States.TestingBeforeSave) {
        if (res?.status === api.TestResultStatus.ok) {
          dispatch({ type: Actions.SaveInitiated });
        } else {
          dispatch({
            type: Actions.SaveFailed,
            error: "Unable to save, query test failed."
          });
        }
      }
    };

    if (
      stateName === States.TestingBeforeSave ||
      stateName === States.Testing
    ) {
      runEffect();
    }
  }, [stateName]);

  let handleTestInitiate = async (saveAfter = false) => {
    let currentFields = getRequiredFields(queryValue);
    let missingTestField = currentFields.find(f => {
      let field = testFields[f];
      if (!field) {
        return true;
      } else {
        return field.length < 1;
      }
    });
    if (missingTestField) {
      dispatch({ type: Actions.EditTestFieldsInitiated });
      return;
    }

    if (saveAfter) {
      dispatch({ type: Actions.TestBeforeSaveInitiated });
    } else {
      dispatch({ type: Actions.TestInitiated });
    }
  };

  let handleSave = async () => {
    if (!databaseId) {
      dispatch({
        type: Actions.SaveFailed,
        error: "Please select a database."
      });
      return;
    }

    if (testResult) {
      if (testResult.query !== queryValue) {
        handleTestInitiate(true);
      } else {
        if (testResult.status === api.TestResultStatus.ok) {
          dispatch({
            type: Actions.SaveInitiated
          });
        } else {
          dispatch({
            type: Actions.SaveFailed,
            error: "Query appears to have an error."
          });
        }
      }
    } else {
      handleTestInitiate(true);
    }
  };

  let handleTestFieldsSave = (
    fields: { [k: string]: string },
    testAfter = false
  ) => {
    dispatch({ type: Actions.EditTestFieldsSuccess, fields, testAfter });
  };

  let handleTestEdit = () => {
    dispatch({ type: Actions.EditTestFieldsInitiated });
  };

  let handleDbSave = async ({
    kind,
    title,
    connection
  }: {
    kind: DbKind;
    title: string;
    connection: DbConnection;
  }) => {
    dispatch({ type: Actions.AddDatabaseInitiated });
    try {
      let id = await api.createDatabase(userId, { kind, title, connection });
      analytics.track("Database created");
      dispatch({
        type: Actions.AddDatabaseSuccess,
        database: { id, kind, title, connection }
      });
    } catch (e) {
      console.error(e);
      dispatch({
        type: Actions.AddDatabaseFailed
      });
      captureException(e);
      message.error("There was a problem creating the db :-( Try again later.");
    }
  };

  let databaseOptions: Array<[string, string]> = databases.map(d => [
    d.id,
    d.title
  ]);
  databaseOptions.push([TestDatabase.id, TestDatabase.title]);
  databaseOptions.push(["__add", "+ Add Database"]);

  let dbModalVisible = [States.AddingDb, States.SavingDb].includes(stateName);
  let saveInProgress = [States.TestingBeforeSave, States.Saving].includes(
    stateName
  );
  let testInProgress = [States.TestingBeforeSave, States.Testing].includes(
    stateName
  );

  return (
    <ContentContainer style={{ gridTemplateColumns: "minmax(300px,500px)" }}>
      <MainDiv>
        <h1>{initialQuery ? "Edit Query" : "Add Query"}</h1>
        <Modal
          title="Add database"
          visible={dbModalVisible}
          onCancel={() =>
            stateName !== States.SavingDb &&
            dispatch({ type: Actions.AddDatabaseCanceled })
          }
          footer={null}
        >
          <EditDatabase
            saveInProgress={stateName === States.SavingDb}
            onSubmit={handleDbSave}
          />
        </Modal>
        <Modal
          title="Edit test variables"
          visible={stateName === States.EditingTestFields}
          onCancel={() => dispatch({ type: Actions.EditTestFieldsCanceled })}
          footer={null}
        >
          <EditTestFields
            fieldNames={requiredTestFields}
            fieldValues={testFields}
            onSave={handleTestFieldsSave}
            onSaveAndTest={(fields: { [k: string]: string }) => {
              handleTestFieldsSave(fields, true);
            }}
          />
        </Modal>
        <EditQuery
          query={queryValue}
          allDatabases={databaseOptions}
          databasesLoading={databasesLoading}
          databaseKind={database && database.kind}
          databaseId={databaseId}
          saveInProgress={saveInProgress}
          testInProgress={testInProgress}
          testResult={testResult}
          error={error}
          onQueryChange={(value: string) =>
            dispatch({ type: Actions.QueryValueChanged, value })
          }
          onDbSelect={(id: string) =>
            dispatch({ type: Actions.DatabaseSelected, id })
          }
          onTestInitiate={handleTestInitiate}
          onTestEdit={
            requiredTestFields.length > 0 ? handleTestEdit : undefined
          }
          onCancel={() => history.push("/queries")}
          onSave={handleSave}
        />
      </MainDiv>
    </ContentContainer>
  );
};

let getInitialState = (query: Props["initialQuery"]): State => {
  return {
    queryValue: query?.value ?? "",
    stateName: States.Idle,
    testFields: query?.testFields ?? {},
    requiredTestFields: [],
    databaseId: query?.databaseId,
    id: query?.id
  };
};

enum Actions {
  TestInitiated,
  TestBeforeSaveInitiated,
  TestCompleted,
  TestFailedToComplete,
  EditTestFieldsInitiated,
  EditTestFieldsCanceled,
  EditTestFieldsSuccess,
  QueryValueChanged,
  DatabaseSelected,
  AddDatabaseCanceled,
  AddDatabaseInitiated,
  AddDatabaseFailed,
  AddDatabaseSuccess,
  SaveInitiated,
  SaveFailed
}

type ActionObject =
  | {
      type:
        | Actions.TestInitiated
        | Actions.TestBeforeSaveInitiated
        | Actions.AddDatabaseCanceled
        | Actions.AddDatabaseInitiated
        | Actions.AddDatabaseFailed
        | Actions.EditTestFieldsInitiated
        | Actions.EditTestFieldsCanceled
        | Actions.SaveInitiated;
    }
  | {
      type: Actions.TestCompleted;
      result: api.TestResult;
    }
  | {
      type: Actions.QueryValueChanged;
      value: string;
    }
  | {
      type: Actions.DatabaseSelected;
      id: string;
    }
  | {
      type: Actions.AddDatabaseSuccess;
      database: Database;
    }
  | {
      type: Actions.SaveFailed;
      error: string;
    }
  | {
      type: Actions.TestFailedToComplete;
      error: string;
    }
  | {
      type: Actions.EditTestFieldsSuccess;
      fields: { [k: string]: string };
      testAfter: boolean;
    };

let reducer = (state: State, action: ActionObject): State => {
  switch (action.type) {
    case Actions.TestInitiated: {
      return {
        ...state,
        error: undefined,
        stateName: States.Testing
      };
    }
    case Actions.TestBeforeSaveInitiated: {
      return {
        ...state,
        error: undefined,
        stateName: States.TestingBeforeSave
      };
    }
    case Actions.TestCompleted: {
      let { result } = action;
      let { stateName } = state;
      let nextStateName;
      if (stateName === States.Testing) {
        nextStateName = States.Idle;
      }
      return {
        ...state,
        stateName: nextStateName ?? stateName,
        testResult: result
      };
    }
    case Actions.TestFailedToComplete: {
      let { error } = action;
      return {
        ...state,
        stateName: States.Idle,
        error
      };
    }
    case Actions.DatabaseSelected: {
      let { id } = action;
      if (id === "__add") {
        return {
          ...state,
          stateName: States.AddingDb
        };
      }

      return {
        ...state,
        databaseId: id
      };
    }
    case Actions.AddDatabaseInitiated: {
      return {
        ...state,
        stateName: States.SavingDb
      };
    }
    case Actions.AddDatabaseSuccess: {
      let { database } = action;
      return {
        ...state,
        stateName: States.Idle,
        databaseId: database.id,
        recentlyCreatedDb: database
      };
    }
    case Actions.AddDatabaseCanceled: {
      return {
        ...state,
        stateName: States.Idle
      };
    }
    case Actions.QueryValueChanged: {
      let { value } = action;
      return {
        ...state,
        queryValue: value
      };
    }
    case Actions.AddDatabaseFailed: {
      return {
        ...state,
        stateName: States.AddingDb
      };
    }
    case Actions.SaveInitiated: {
      return {
        ...state,
        stateName: States.Saving,
        error: undefined
      };
    }
    case Actions.SaveFailed: {
      let { error } = action;
      return {
        ...state,
        stateName: States.Idle,
        error
      };
    }
    case Actions.EditTestFieldsInitiated: {
      let currentFields = getRequiredFields(state.queryValue);
      if (currentFields.length === 0) {
        message.info("No variables found in current query.");
        return {
          ...state,
          requiredTestFields: []
        };
      }
      return {
        ...state,
        stateName: States.EditingTestFields,
        requiredTestFields: currentFields
      };
    }
    case Actions.EditTestFieldsCanceled: {
      return {
        ...state,
        stateName: States.Idle
      };
    }
    case Actions.EditTestFieldsSuccess: {
      let { fields, testAfter } = action;
      return {
        ...state,
        testFields: fields,
        error: undefined,
        stateName: testAfter ? States.Testing : States.Idle
      };
    }
    default: {
      throw new Error(`Unhandled action type`);
    }
  }
};

// enum TestState {
//   passing,
//   stale,
//   failing
// }

// // Move us through saving state machine
// useEffect(() => {
//   if (testInProgress) return;
//   if (!saveInProgress) return;

//   if (!testResult) {
//     handleTestInitiate()
//     return
//   }

//   switch (getTestState(testResult, queryValue)) {
//     case TestState.passing: {
//       // do save
//     }
//     case TestState.stale: {
//       handleTestInitiate()
//     }
//   }

//   if (saveInProgress) {
//     if (testPassing(testResult, queryValue) && saveInProgress) {
//       // do
//     }
//   }
// }, [testResult, saveInProgress, testInProgress]);

// let getTestState = (testResult: api.TestResult, queryValue: string): TestState => {
//   if (!testResult) return TestState.stale;

//   if (testResult.query === queryValue) {
//     if (testResult.status === api.TestResultStatus.ok) {
//       return TestState.passing
//     } else {
//       return TestState.failing
//     }
//   }

//   return TestState.stale
// };

type RunTestResult = [api.TestResult, null] | [null, string];
export let runTest = async ({
  databaseId,
  value,
  fields,
  id
}: {
  databaseId: string;
  value: string;
  fields: { [k: string]: string };
  id?: string;
}): Promise<RunTestResult> => {
  try {
    let r = await api.testQuery(value, databaseId, fields);
    analytics.track("Query tested", {
      databaseId,
      result: r.status,
      id
    });
    let result;
    if (r.status === "fail") {
      result = {
        status: api.TestResultStatus.fail,
        log: r.error,
        query: value
      };
    } else {
      result = {
        status: api.TestResultStatus.ok,
        log: JSON.stringify(r.result),
        query: value
      };
    }
    return [result, null];
  } catch (e) {
    console.error(`Caught exception: ${e}`);
    captureException(e);
    return [null, "Something went wrong while running your test :( Try again?"];
  }
};

export default EditQueryContainer;

const DEFAULT_MONGO_QUERY = `// Two available variables:
//   * \`db\` - Use this to build your query
//   * \`done(result, error)\` - Pass result and/or error to this function
// Example 1:
db.collection("users").find().limit(100).toArray(
  (err, res) => done(res, err)
)
// Example 2 (includes \`fields\`):
db.collection("users").find({ email: {{email}} }).toArray(
  (err, res) => done(res, err)
)
`;
