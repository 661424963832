import React, { useState, useEffect } from "react";
import AceEditor from "react-ace";
import { Select, Button, Menu } from "antd";

import { TestResult, TestResultStatus } from "./api";
import CalloutBubble from "./CalloutBubble";
import { Collapse, Icon } from "antd";

import "ace-builds/src-noconflict/mode-sql";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-github";
import styled from "styled-components";
import { DbKind } from "./AppTypes";

const { Option } = Select;
const { Panel } = Collapse;

export type CalloutableComponents =
  | "db-select"
  | "edit-query"
  | "test-query"
  | "save-query";

interface Props {
  databaseId?: string;
  databaseKind?: string;
  query: string;
  allDatabases: Array<[string, string]>;
  calloutComponent?: CalloutableComponents;
  testInProgress: boolean;
  saveInProgress: boolean;
  testResult?: TestResult;
  error?: string;
  databasesLoading?: boolean;
  onTestInitiate(): void;
  onTestEdit?(): void;
  onDbSelect(id: string): void;
  onQueryChange(val: string): void;
  onCancel(): void;
  onSave(): void;
}

export default function EditQuery({
  databaseId,
  databaseKind,
  query,
  allDatabases,
  calloutComponent,
  testInProgress,
  saveInProgress,
  testResult,
  onTestInitiate,
  onTestEdit,
  onDbSelect,
  onQueryChange,
  databasesLoading,
  error,
  onCancel,
  onSave
}: Props) {
  // We need to control this in state. This gives us an opporunity to do a re-render
  // shortly after it expands (CSS Transition) - which is necessary to allow
  // CalloutBubble to track its position on the DOM.
  const [resultExpanded, setResultExpanded] = useState(false);
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    setTimeout(() => forceUpdate(), 300);
  }, [resultExpanded]);

  useEffect(() => {
    if (testResult && testResult.status === TestResultStatus.fail) {
      setResultExpanded(true);
    }
    return () => {};
  }, [testResult]);

  const getTestResultHeader = () => {
    if (testResult) {
      if (testInProgress) {
        return (
          <>
            <Icon type="loading" /> Result
          </>
        );
      } else {
        if (testResult.status === TestResultStatus.ok) {
          return "✅ Result";
        } else {
          return "❌ Result";
        }
      }
    }

    return "Result";
  };

  return (
    <StyledContainer id="edit-query">
      <CalloutBubble active={calloutComponent === "db-select"}>
        <Select
          placeholder="Select database"
          value={databaseId}
          onChange={(value: string) => onDbSelect(value)}
          loading={databasesLoading}
        >
          {allDatabases.map(([id, name]) => (
            <Option value={id} key={id}>
              {name}
            </Option>
          ))}
        </Select>
      </CalloutBubble>

      {databaseId && (
        <>
          {/* {databaseKind === DbKind.mongodb && (
            <p>
              💡 See <a>these instructions</a> for writing queries for Mongo.
            </p>
          )} */}
          <CalloutBubble active={calloutComponent === "edit-query"}>
            <AceEditor
              mode={databaseKind === DbKind.mongodb ? "javascript" : "sql"}
              theme="github"
              value={query}
              onChange={val => {
                onQueryChange(val);
              }}
              focus={true}
              style={{ width: "100%", height: "150px" }}
              name="edit-query"
            />
          </CalloutBubble>
          {testResult && (
            <Collapse
              bordered={false}
              activeKey={resultExpanded ? ["1"] : []}
              onChange={keys =>
                keys.length > 0
                  ? setResultExpanded(true)
                  : setResultExpanded(false)
              }
              expandIcon={({ isActive }) => (
                <Icon type="caret-right" rotate={isActive ? 90 : 0} />
              )}
            >
              <Panel
                header={getTestResultHeader()}
                key="1"
                style={customPanelStyle}
              >
                <StyledResultPre>
                  <code>{testResult.log}</code>
                </StyledResultPre>
              </Panel>
            </Collapse>
          )}
          {error && (
            <p
              style={{
                textAlign: "right",
                color: "#f5222d",
                margin: "0px"
              }}
            >
              {error}
            </p>
          )}
          <StyledActionButtons>
            <div style={{ gridArea: "cancel" }}>
              <Button
                disabled={saveInProgress}
                onClick={onCancel}
                style={{ justifySelf: "flex-start" }}
                type="ghost"
              >
                Cancel
              </Button>
            </div>
            <div style={{ gridArea: "test" }}>
              <CalloutBubble active={calloutComponent === "test-query"}>
                <Button.Group
                  style={{ display: "grid", gridTemplateColumns: "1fr auto" }}
                >
                  <Button
                    loading={testInProgress}
                    disabled={query.length < 1 || saveInProgress}
                    onClick={() => onTestInitiate()}
                  >
                    Test
                  </Button>
                  {onTestEdit && (
                    <Button
                      disabled={
                        query.length < 1 || saveInProgress || testInProgress
                      }
                      onClick={onTestEdit}
                      style={{ padding: "5px" }}
                    >
                      <Icon type="edit" />
                    </Button>
                  )}
                </Button.Group>
              </CalloutBubble>
            </div>
            <div style={{ gridArea: "save" }}>
              <CalloutBubble active={calloutComponent === "save-query"}>
                <Button
                  loading={saveInProgress}
                  disabled={testInProgress || query.length < 1}
                  onClick={onSave}
                  type="primary"
                >
                  Save
                </Button>
              </CalloutBubble>
            </div>
          </StyledActionButtons>
        </>
      )}
    </StyledContainer>
  );
}

const TestButtonMenu = (
  <Menu>
    <Menu.Item key="1"></Menu.Item>
    <Menu.Item key="2">2nd item</Menu.Item>
    <Menu.Item key="3">3rd item</Menu.Item>
  </Menu>
);

const useForceUpdate = () => {
  const [value, setValue] = useState(true);
  return () => setValue(!value);
};

const customPanelStyle = {
  background: "#f7f7f7",
  borderRadius: 4,
  marginBottom: 24,
  border: 0,
  overflow: "hidden"
};

const StyledActionButtons = styled.div`
  display: grid;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  grid-template-areas: "cancel spacer test save";
  grid-template-columns: auto 1fr auto auto;
  justify-content: right;

  @media (min-width: 650px) {
    grid-template-columns: 100px 1fr 100px 100px;
    button {
      width: 100%;
    }
  }
`;

const StyledContainer = styled.div`
  display: grid;
  grid-row-gap: 15px;
`;

const StyledResultPre = styled.pre`
  max-height: 300px;
  max-width: 500px;
  overflow-y: scroll;
  overflow-x: scroll;
  word-break: break-word;
  white-space: pre-wrap;
`;
