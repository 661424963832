import Firebase from "./Firebase";
import { auth as firebaseAuth, auth, database } from "firebase/app";
import { message } from "antd";
import { useState, useEffect } from "react";
import { DbConnection, DbKind } from "./AppTypes";
import { captureException } from "@sentry/core";

// export const loginWithGithub = () => {
//   const provider = new firebaseAuth.GithubAuthProvider();
//   provider.addScope("user:email");

//   Firebase.auth().signInWithRedirect(provider);
// };

type TestQueryResponse =
  | { status: "ok"; result: unknown }
  | { status: "fail"; error: string };

export const testQuery = async (
  queryValue: string,
  databaseId: string,
  fields: { [k: string]: string }
): Promise<TestQueryResponse> => {
  const user = getCurrentUser();
  if (!user) {
    throw new Error(`Expected a user to be logged in to reach this point.`);
  }
  const token = await user.getIdToken();
  const r = await fetch(getUrl("/validate/query"), {
    method: "post",
    body: JSON.stringify({
      query: queryValue,
      databaseId,
      fields
    }),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json"
    }
  });
  if (r.status !== 200) {
    throw new Error(
      `Received status ${r.status} when trying to validate query`
    );
  }
  return await r.json();
};

type TestDbResponse = { status: "ok" } | { status: "fail"; error: string };

export const testDb = async (
  database: Omit<Database, "id">
): Promise<TestDbResponse> => {
  const r = await fetch(getUrl("/validate/database"), {
    method: "post",
    body: JSON.stringify(database),
    headers: {
      "Content-Type": "application/json"
    }
  });
  if (r.status !== 200) {
    throw new Error(
      `Received status ${r.status} when trying to validate database.`
    );
  }
  return await r.json();
};

const API_HOST = process.env.REACT_APP_API_HOST;
if (!API_HOST) {
  throw new Error(`Expected the ENV variable "REACT_APP_API_HOST" to be set.`);
}

const getUrl = (path: string) => {
  return API_HOST + path;
};

export const loginAnonymousUser = async () => {
  const usercred = await Firebase.auth()
    .signInAnonymously()
    .catch(error => {
      console.error(error);
      message.error("There was an error :-(");
    });
  if (!usercred) {
    throw new Error(
      `Expected Firebase#signInAnonymously() to return a user cred`
    );
  }
  const u = getUserFromCredential(usercred);
  return u.uid;
};

export const signInUser = async (email: string, password: string) => {
  const usercred = await Firebase.auth().signInWithEmailAndPassword(
    email,
    password
  );
  const u = getUserFromCredential(usercred);
  return u.uid;
};

export const signUpUser = async (
  email: string,
  password: string
): Promise<string> => {
  const anonUser = getCurrentUser();
  if (anonUser) {
    // User is already created, we just need to call the link function to 'upgrade'
    const credential = firebaseAuth.EmailAuthProvider.credential(
      email,
      password
    );
    try {
      const userCred = await anonUser.linkWithCredential(credential);
      const user = getUserFromCredential(userCred);
      return user.uid;
    } catch (error) {
      console.log("Error upgrading anonymous account", error);
      captureException(error);
      const userCred = await Firebase.auth().createUserWithEmailAndPassword(
        email,
        password
      );
      const user = getUserFromCredential(userCred);
      return user.uid;
    }
  } else {
    console.log("No anon account. Creating a new one.");
    const userCred = await Firebase.auth().createUserWithEmailAndPassword(
      email,
      password
    );
    const user = getUserFromCredential(userCred);
    return user.uid;
  }
};

const getUserFromCredential = (userCred: firebaseAuth.UserCredential) => {
  const { user } = userCred;
  if (!user) {
    throw new Error(`Expected Firebase to return a User in UserCredential.`);
  }
  return user;
};

export const getCurrentUser = (): firebase.User | null => {
  return Firebase.auth().currentUser;
};

export const logoutUser = () => {
  return Firebase.auth().signOut();
};

export const saveQuery = async (
  userId: string,
  queryId: string,
  {
    databaseId,
    value
  }: {
    databaseId: string;
    value: string;
  }
) => {
  const ref = userDoc(userId)
    .collection("queries")
    .doc(queryId);
  return ref.update({
    databaseId,
    value
  });
};

export const createQuery = async (
  userId: string,
  {
    databaseId,
    value
  }: {
    databaseId: string;
    value: string;
  }
) => {
  // TODO: Validate that the user owns this db? This must happen on BE
  const ref = userDoc(userId)
    .collection("queries")
    .doc();
  await ref.set({
    databaseId,
    value
  });
  return ref.id;
};

export const createDatabase = async (
  userId: string,
  {
    kind,
    title,
    connection
  }: { kind: DbKind; title: string; connection: DbConnection }
) => {
  // TODO: Validate that the user owns this db? This must happen on BE
  const ref = userDoc(userId)
    .collection("databases")
    .doc();
  await ref.set({
    kind,
    title,
    connection
  });
  return ref.id;
};

export const deleteQuery = (userId: string, id: string) => {
  return userDoc(userId)
    .collection("queries")
    .doc(id)
    .delete();
};

interface Query {
  id: string;
  databaseId: string;
  pathKey: string;
  value: string;
  requestCount?: number;
}

export interface Database {
  id: string;
  title: string;
  kind: DbKind;
  connection: DbConnection;
}

export const useFirebase = (userId: string) => {
  const [queries, setQueries] = useState<Query[]>([]);
  const [loadingQueries, setLoadingQueries] = useState(true);
  const [loadingDatabases, setLoadingDatabases] = useState(true);
  const [databases, setDatabases] = useState<Database[]>([]);

  useEffect(() => {
    const unsubQueries = userDoc(userId)
      .collection("queries")
      .onSnapshot(snap => {
        const queries: Query[] = snap.docs
          .map(d => {
            const rest: any = d.data();
            return { id: d.id, ...rest };
          })
          .sort((a, b) => b.createdAt - a.createdAt);
        setQueries(queries);
        setLoadingQueries(false);
      });
    const unsubDatabases = userDoc(userId)
      .collection("databases")
      .onSnapshot(snap => {
        const databases: Database[] = snap.docs.map(d => {
          const rest: any = d.data();
          return { id: d.id, ...rest };
        });
        setDatabases(databases);
        setLoadingDatabases(false);
      });

    return () => {
      unsubQueries();
      unsubDatabases();
    };
  }, []);

  if (loadingQueries || loadingDatabases) {
    return { loading: true, queries: [], databases: [] };
  } else {
    return { loading: false, queries, databases };
  }
};

const userDoc = (id: string) => {
  return Firebase.firestore().doc(`users/${id}`);
};

export enum TestResultStatus {
  ok,
  fail
}

export interface TestResult {
  status: TestResultStatus;
  log: string;
  query: string;
}
