import React from "react";
import styled from "styled-components";

export const ContentContainer = styled.div`
  margin: 50px 10px 30px 10px;
  display: grid;
  justify-content: center;
`;

export const VerticallyCenteredContentContainer = styled.div`
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
`;

export const MainDiv = styled.div`
  background-color: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
  border-radius: 2px;
  max-width: 500px;
  padding: 15px;
  display: grid;
`;

export const Brand: React.FC<{
  onClick?: () => void;
  style?: React.CSSProperties;
}> = ({ onClick, style }) => (
  <StyledBrand style={style} onClick={onClick}>
    decode
  </StyledBrand>
);

const StyledBrand = styled.h1`
  font-family: "Lora", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
    "Helvetica Neue", sans-serif;
  cursor: pointer;
`;
