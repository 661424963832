import React, { useState, ReactElement } from "react";

import { Tooltip } from "antd";
import { TooltipProps } from "antd/lib/tooltip";

import "./LocalMessage.css";

interface Props {
  disappearAfter: number;
  children: (showMessage: (msg: string) => void) => ReactElement;
}
const LocalMessage: React.FC<Props> = ({
  disappearAfter,
  children,
  ...rest
}) => {
  const [msg, setMsg] = useState<string | undefined>();
  const showMessage = (msg: string) => {
    setMsg(msg);
    setTimeout(() => setMsg(undefined), disappearAfter);
  };

  return (
    <Tooltip
      overlayClassName="LocalMessage"
      transitionName=""
      placement="right"
      visible={msg ? true : !!msg}
      title={msg}
      {...rest}
    >
      {children && children(showMessage)}
    </Tooltip>
  );
};

export default LocalMessage;
